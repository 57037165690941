var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "게시판",
      "tabActive": "공지사항",
      "bg": "/images/test.jpg"
    }
  }), _c('page-section', [!_vm.$route.params._notification ? _c('notification-list') : _c('notification-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }