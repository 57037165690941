<template>
    <div class="board-container">
        <table class="board-list">
    		<thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width:10%;" class="board-list__txt d-none d-lg-table-cell">{{$t('common.number')}}</th>
                    <th style="width:70%;" class="board-list__tit">{{$t('common.제목')}}</th>
                    <th style="width:20%;" class="board-list__txt">{{$t('common.작성일')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(notification, index) in fixed" :key="index" @click="$router.push(`${$route.path}/${notification._id}`)">
                    <td align="center" class="board-list__txt d-none d-lg-table-cell">
                        <v-icon>mdi-alert-circle-outline</v-icon>
                    </td>
                    <td align="center" class="board-list__tit">
                        <div class="ellip">
                            <span>{{ notification.subject }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{ notification.createdAt.toDate() }}
                    </td>
                </tr>

                <tr v-for="(notification, index) in notifications" :key="index" @click="$router.push(`${$route.path}/${notification._id}`)">
                    <td align="center" class="board-list__txt d-none d-lg-table-cell">
                        <span>{{ (page-1)*limit + index + 1 }}</span>
                    </td>
                    <td align="center" class="board-list__tit">
                        <div class="ellip">
                            <span>{{ notification.subject }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{ notification.createdAt.toDate() }}
                    </td>
                </tr>
    		</tbody>
    	</table>
        <div class="pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="search(true)" />
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { mdiAlertCircleOutline } from '@mdi/js';
export default {
    components: {
    },
    props: {
        value: { type: String, default: null }
    },
    data() {
        return {
            mdiAlertCircleOutline,

            filter: { isNotice: false },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 20,

            fixed: [],
            notifications: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init() {
            await this.search();
		},
        async search() {
            this.$router.push({
                query: Object.assign({}, this.query, { page: this.page })
            });

            var { notifications: fixed } = await api.v1.center.notifications.gets({
                headers: {
                    limit: 0
                },
                params: { isNotice: true }
            });

            this.fixed = fixed;

            var { summary, notifications } = await api.v1.center.notifications.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.notifications = notifications;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
    }
}
</script>